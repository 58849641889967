@charset "UTF-8";

$primary-color:#ffcc00;
$sub-color:#41d293;
$sub-text-color:#333;
$disable-color:#aaa;
$emp-color:#ff3a44;
$border-color: #e9e9e9;

$base-url: "../img/";


/* 
mobile : 767
tablet : 768 ~ 1139
desktop : 1140 ~ 1400 
*/
$breakpoint_mobile: 767px;
$breakpoint_tablet: 768px;
$breakpoint_desktop: 1139px;

/* Responsive inner content */
$inner_mobile : calc(100% - 38px);
$inner_tablet : calc(100% - 80px);
$inner_desktop : calc(100% - 360px);


@mixin mobile {
    @media (max-width: $breakpoint_mobile) {
      @content;
    }
}

@mixin tablet {
    @media (min-width: $breakpoint_tablet) and (max-width: $breakpoint_desktop) {
      @content;
    }
}


@mixin calc($property, $expression) {
	#{$property}: -moz-calc(#{$expression});
	#{$property}: -webkit-calc(#{$expression});
	#{$property}: calc(#{$expression});
}

@mixin radio_component() {
  input[type="radio"] {
    display: none;
  }
  input[type="radio"] + label {
    display: inline-block;
    width: 100%;
    font-size: 1.25em;
  }
  input[type="radio"] + label span:first-child {
    display: inline-block;
    width: 30px;
    height: 30px;
    background: url("#{$base-url}radio.png") left top no-repeat;
    background-size: 30px; /* background-position: 0 -24px; */
    color: #777;
    text-indent: 36px;

    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0px;
    text-align: left;

    cursor: pointer;
    vertical-align: middle;
    margin: auto;
    margin-right: 15px;
  }
  input[type="radio"]:checked + label span {
    height: 30px;
  }
  input[type="radio"]:checked + label span:first-child {
    background: url("#{$base-url}radio_on.png") left top no-repeat;
    background-size: 30px;
    /* background-position: 0 0; */
  }
}

@mixin check_component() {
	input[type="checkbox"] {
		display: none;
	}
	input[type="checkbox"]+label {
		display: inline-block;
		width: 100%;
		font-size: 1.25em;
	}
	input[type="checkbox"]+label span:first-child {
		display: inline-block;
		width: 30px; height: 30px;
		background:url('#{$base-url}checkbox.png') left top no-repeat;
		background-size: 30px;  /* background-position: 0 -24px; */
		color: #777; font-weight: 700; text-indent: 36px; line-height: 1.7; cursor: pointer;
        vertical-align: middle; margin: auto; margin-right: 15px;
	}
    input[type="checkbox"]:checked+label span { height: 30px; }
	input[type="checkbox"]:checked+label span:first-child {
        background:url('#{$base-url}checkbox_on.png') left top no-repeat;
        background-size: 30px;
		/* background-position: 0 0; */
	}
}


@mixin default_btn() {
    background:#0066FA; padding:24px 106px; border-radius: 5px;
    color:#fff; font-weight: 800; font-size: 1.563rem; line-height: 130%;
}


@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}


@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {@content; }
    @-moz-keyframes #{$name} {@content;}
    @-ms-keyframes #{$name} {@content;}
    @keyframes #{$name} {@content;} 
}

@mixin animation ($delay, $duration, $animation, $timing: linear, $direction: forward, $fillmode: forwards) {
    -webkit-animation-delay: $delay;
    -webkit-animation-duration: $duration;
    -webkit-animation-name: $animation;
    -webkit-animation-timing-function: $timing;
    -webkit-animation-fill-mode: $fillmode;
    -webkit-animation-direction: $direction;
 
    -moz-animation-delay: $delay;
    -moz-animation-duration: $duration;
    -moz-animation-name: $animation;
    -moz-animation-timing-function: $timing;
    -moz-animation-fill-mode: $fillmode;
    -moz-animation-direction: $direction;
 
    animation-delay: $delay;
    animation-duration: $duration;
    animation-name: $animation;
    animation-timing-function: $timing;
    animation-fill-mode: $fillmode;
    animation-direction: $direction;
}

.animation-slide-in-up{
  @include animation(0, 0.75s, animation-slide-in-up, cubic-bezier(0.25, 0.1, 0, 0.9));
}

.animation-slide-in-down{
  @include animation(0, 0.75s, animation-slide-in-down, cubic-bezier(0.25, 0.1, 0, 1.25));
}

.animation-fade-in{
	@include animation(0, 0.3s, animation-fade-in, ease);
}

// Fade-in
@include keyframes(animation-fade-in) {
    0% {
        opacity: 0;
    }
 
    100% {
        opacity: 1;
    }
}

@include keyframes(animation-slide-in-up) {
    0% {
        @include transform( translate(0,100%) );
    }
 
    100% {
        @include transform( translate(0,0) );
    }
}

@include keyframes(animation-slide-in-down) {
    0% {
        @include transform( translate(0,0) );
    }
 
    100% {
        @include transform( translate(0,100%) );
    }
}








